function Authenticator() {
	var self = this;

	self.apiEndpoint = '/qless/authenticator';
	self.authEndpoint = '/qless/api/v1/employee/authInfo';
	self.locs = {
		"calendar": '/calendar/',
		"cec": '/cec',
		"config": '/qlessConfig',
		"dashboard": '/dashboard',
		"kiosk": '/kiosk/app/location',
		"monitor": '/monitor/',
		"reports": '/qless/reports',
	};
	self.remember = false;
	self.serverLogoutUrl = '/qless/logout';
	self.store = new Store();



	/**
	 * Dispatches a custom event
	 *
	 * @method broadcastError
	 * @param {String} type Type of error event
	 * @return none
	 */
	self.broadcastError = function (type) {
		var errorType = type + 'Error';
		var errorEvent = null;


		if (typeof (Event) === 'function') {
			errorEvent = new Event(errorType);
		}
		else {
			errorEvent = document.createEvent('Event');
			errorEvent.initEvent(errorType, true, true);
		}

		document.dispatchEvent(errorEvent);
	};


	/**
	 * Checks the login for au cookies
	 *
	 * @method checkLogin
	 * @param [Function] callback
	 * @return none
	 */
	self.checkLogin = function (callback) {
		if (self.getAUCookies()) {
			callback();
		}
		else {
			self.logout();
		}
	};


	/**
	 * Hack-ish way of forwarding a user to the app based on
	 * - strings found in the user name OR
	 * - the document.referrer OR
	 * - Queue Manager 2
	 *
	 * @method forwardUserToApp
	 * @param {XMLDocument} data
	 * @return none
	 */
	self.forwardUserToApp = function (data) {
		var loc = self.locs.cec;
		var authinfo = $(data).find('authInfo');
		var loginApp = self.store.get('loginapp');
		var ref = self.getQueryParam('ref');
		var userid = null;

		// Make sure there was an authInfo node
		if (authinfo) {
			userid = authinfo[0].getAttribute('userid');

			// Make sure there was a userid attribute
			if (userid) {

				// If the loginApp is set, send the user there
				if (loginApp) {
					loc = self.getLocByLoginApp(loginApp);
				}
				else {
					// Transform the username to a lower case for comparison
					userid = userid.toLowerCase();

					if (userid.indexOf('monitor') > -1) {
						loc = self.locs.monitor;
					}
					else if (userid.indexOf('kiosk') > -1) {
						loc = self.locs.kiosk;
					}
					// else if ( document.referrer ) {
					// 	loc = document.referrer;
					// }
					else if (ref) {
						loc = ref;
					}
				}

				// Send the user to the best known app location
				document.location.href = loc;
				return;
			}
		}

		// If we got here, we failed to forward the user
		self.broadcastError('forwarding');
	};


	/**
	 * Looks through cookies found and returns any AU cookies
	 *
	 * @method getAUCookies
	 * @return {Array | Null}
	 */
	self.getAUCookies = function () {
		var arr = [];
		var cookies = document.cookie.split(';');

		if (cookies) {
			cookies.forEach(function (cookie) {
				var parts = cookie.trim().split('=');

				if (parts[0].indexOf('au') === 0) {
					arr.push({
						name: parts[0],
						value: parts[1]
					});
				}
			});
		}

		return (arr.length > 0) ? arr : null;
	};


	/**
	 * Calls the authInfo API to retrieve role information
	 *
	 * @method getAuthInfo
	 * @return none
	 */
	self.getAuthInfo = function () {
		$.ajax({
			method: 'GET',
			url: self.authEndpoint,
			success: function (data) {
				if (data) {
					if (self.remember) {
						self.saveAUCookies();
					}

					self.forwardUserToApp(data);
				}
				else {
					// Broadcast event so UI can respond.
					self.broadcastError('login');
				}
			}
		});
	};


	/**
	 * Determines the app url
	 * 
	 * @method getLocByLoginApp
	 * @param {String} app
	 * @returns {String}
	 */
	self.getLocByLoginApp = function (app) {
			return (self.locs[app]) ? self.locs[app] : self.locs.cec;
	};


	/**
	 * This method searches the browser's URL for the query string for a
	 * key=value pair with the provided key. If found, returns the value
	 *
	 * @method getQueryParam
	 * @param {String} name Key name to find the value for
	 * @return {String} Returns the string value corresponding to the key or false
	 */
	self.getQueryParam = function (name) {
		var match = new RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
		return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
	};


	/**
	 * Redirects the User to the Linebuster (Linemonkey) Login page
	 *
	 * @method gotoLogin
	 * @return none
	 */
	self.gotoLogin = function () {
		var target = self.getQueryParam("target");

    if (!target) {
      // If we haven't been told what Application we are logging out from,
      // infer it from the referrer.
      var defaultApplication = "cec";

      // Detect the application from app specific domains such as `monitor.[ENV].qless.com` and `kiosk.[ENV].qless.com`
      var applicationFromDomain = document.referrer
        .substring(document.referrer.indexOf("//") + 2)
        .split(".")[0];
      var hasApplicationDomain = ["kiosk", "monitor"].includes(
        applicationFromDomain
      );
      // Detect the application from it's known sub-path `/cec/`, `/monitor/`, `/kiosk/` etc.
      var applicationFromPath = document.referrer
        .match(/(\/cec|calendar|kiosk|monitor\/)/)[0]
        .replace("/", "");

      target = hasApplicationDomain
        ? applicationFromDomain
        : applicationFromPath
					? applicationFromPath
					: defaultApplication;
    }

		var url = (target === "cec") 
			? self.serverLogoutUrl + (target ? "?target=/" + target : "")
			: '/login?target=/' + target;

    // TODO: What heuristics can we use to identify an SSO logout?
		// The Linemonkey Logout servlet sets a Logout cookie with `target` and `idp`. 
		// Is that something we can use?

    // And why does it go to `logout` when the original goes to `login`.
		// Should we have a separate gotoLogout?
    document.location.replace(url);
	};

	self.redirectToBackendServiceLogout = self.gotoLogin;


	/**
	 * Reads paths from a json file
	 *
	 * @method loadPaths
	 * @param {String} jsonPath Path to file to load
	 * @return none
	 */
	self.loadPaths = function (jsonPath) {
		// Only process if a path was specified
		if (jsonPath) {
			$.ajax({
				method: 'GET',
				url: jsonPath,
				dataType: 'json',
				// If data was returned and it looks like a JSON object
				// replace known paths with that data
				success: function (data) {
					if (typeof data === 'object') {
						self.locs = data;
					}
				}
			});
		}
	};


	/**
	 * Passes authentication information to the API service
	 *
	 * @method login
	 * @param {String} principal 
	 * @param {String} sCredentials
	 * @param {Boolean} remember
	 * @return none
	 */
	self.login = function (principal, credentials, remember) {
		var payload = {
			principal: principal,
			credentials: credentials,
			remember: (remember === true)
		};

		var useSSO = principal.indexOf('//') === 0;

		self.remember = (remember === true);

		// Remove any outstanding auth or identity cookies
		self.removeLoginCookies();

		if (useSSO) {
			var form = document.getElementById('loginForm');
			var tgt = self.getLocByLoginApp(document.getElementById('app'));

			document.getElementById('target').value = tgt;

			form.action = '/idp-auth0/apiAuth0Authenticate';
			form.method = 'post';

			setTimeout(function () {
				form.submit();
			}, 100);
		}
		else {
			// Request data from the API
			$.ajax({
				method: 'POST',
				url: self.apiEndpoint,
				data: payload,
				success: function (data) {
					// If auth cookies are found, process them to figure out
					// where to send the user to attempt to get them to the
					// correct application
					if (self.getAUCookies()) {
						self.getAuthInfo();
					}
					// Broadcast the error event so UI can respond.
					else {
						self.broadcastError('login');
					}
				},
				error: function () {
					self.broadcastError('connection');
				}
			});
		}
	};


	/**
	 * Removes credentials for the user and redirects them to the login page
	 *
	 * @method logout
	 * @return none
	 */
	self.logout = function () {
		self.removeLoginCookies();
		self.gotoLogin();
	};


	/**
	 * Clears any existing login cookies
	 *
	 * @method removeLoginCookies
	 * @return none
	 */
	self.removeLoginCookies = function () {
		// Get authorization cookies
		var cookies = self.getAUCookies();

		// If any auth cookies were found, remove them
		if (cookies) {
			cookies.forEach(function (cookie) {
				self.store.del(cookie.name);
			});
		}

		// Remove the identity cookie
		self.store.del('i');

		// Remove the 'b' cookie
		self.store.del('b');

		// Temporary hack
		self.store.del('selectedLocationIds');
		//self.store.del('JSESSIONID'); // session is required to logout SSO
		self.store.del('_ga');
		self.store.del('_gat');
		self.store.del('_gid');
	};


  /**
   * Saves any AU (authentication) cookies as permanent cookies
   * 
   * @method saveAUCookies
   * @return none
   */
	self.saveAUCookies = function () {
		var cookies = self.getAUCookies();

		cookies.forEach(function (cookie) {
			self.store.set(cookie.name, cookie.value, true, true);
		});
	};

	// API
	return self;
};

