function Store() {
  var self = this;

  // Whether the user's browser has cookies enabled
  self.cookiesEnabled = navigator.cookieEnabled;

	/**
	* Returns a boolean indicating the browser has the capacity to store
	* either in cookies or local storage
	*
	* @method canStore
	* @return {Boolean}
	*/
  self.canStore = function () {
    return (self.cookiesEnabled || self.storageEnabled());
  };


	/**
	* Removes an item from local storage or cookie
	*
	* @method del
	* @param {String} key The token to delete
	* @return none
	*/
  self.del = function (key) {
    // Retrieves a list of cookies from the browser
    var cookies = document.cookie.split(';');

    // First look in the local storage for the item
    if (self.storageEnabled() === true) {
      if (localStorage.getItem(key)) {
        localStorage.removeItem(key);
      }
    }

    // Now attempt to remove any cookies with the key
    // Loop over each cookie found
    cookies.forEach(function (cookie) {
      // remove any extra white space around the cookie
      cookie = $.trim(cookie);

      // If the cookie name requested is the current cookie
      // strip away the cookie name, and return the value
      if (cookie.indexOf(key + '=') === 0) {
        if (cookie.substring(key.length + 1, cookie.length)) {
          document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        }
      }
    });
  };


	/**
	* Retrieves an item from storage
	*
	* @method get
	* @param {String} key The token to retrieve
	* @return {String}
	*/
  self.get = function (key) {
    // Retrieves a list of cookies from the browser
    var cookies = document.cookie.split(';');
    // Local storage value
    var lVal = null;
    // String to compare
    var nameEQ = key + '=';

    // Try to retrieve the value from local storage first
    if (self.storageEnabled() === true) {
      lVal = localStorage.getItem(key);

      if (lVal && lVal !== '') {
        return lVal;
      }
    }

    // Loop over each cookie found
    cookies.forEach(function (cookie) {
      // remove any extra white space around the cookie
      cookie = $.trim(cookie);

      // If the cookie name requested is the current cookie
      // strip away the cookie name, and return the value
      if (cookie.indexOf(nameEQ) === 0) {
        lVal = cookie.substring(nameEQ.length, cookie.length);

        if (lVal && lVal !== '') {
          return cookie.substring(nameEQ.length, cookie.length);
        }
      }
    });

    // No matching cookie found; return null
    return null;
  };


	/**
	* Sets an item in local storage or cookie
	*
	* @method set
	* @param {String} key Token of the item to set
	* @param {String} val Value of the item to set
	* @param {Boolean} useCookie Whether or not to set via cookie (even if LocalStorage is available)
	* @param {Boolean} persist Whether the cookie should persist, or default to a session cookie
	* @return
	*/
  self.set = function (key, val, useCookie, persist) {
    var now = new Date();
    var cookie = '';

    now.setFullYear(now.getFullYear() + 1);

    if (useCookie === true || self.storageEnabled() === false) {
      cookie = key + '=' + val + '; path=/;';

      if (persist) {
        cookie += 'expires=' + now.toUTCString() + ';';
      }

      document.cookie = cookie;
    }
    else {
      localStorage.setItem(key, val);
    }
  };


	/**
	* Indicates whether the browser supports local storage
	*
	* @method storageEnabled
	* @return {Boolean}
	*/
  self.storageEnabled = function () {
    return (typeof (Storage) !== 'undefined');
  };


  // expose API
  return self;
}