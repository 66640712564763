// Create an instance of an Authenticator
var auth = new Authenticator();
var store = new Store();
var appChoice = "cec";

/**
 * Listens for a thrown custom event and responds in the UI
 * to notify the user the login attempt failed
 *
 * @method document.addEventListenter.loginError
 * @param {String} Custom event name
 * @param {Function} Callback handler
 * @return none
 */
document.addEventListener(
  "loginError",
  function () {
    $("#loginFailed").removeClass("is-hidden");
    $("#login-submit").removeClass("btn-submit-logging");
  },
  false
);

/**
 * Determines if the user is at a consumer application domain
 * (Kiosk/Monitor). If they are, the application will be pre-set
 * for them and the application picker will not appear.
 *
 * @method preselectApplication
 * @returns none
 */
function preselectApplication() {
  var hostname = document.location.hostname;
  var target = auth.getQueryParam("target");

  // Hide the "App Picker" if the current domain is application specific i.e.
  // For Kiosk or Monitor. kiosk.us.qless.com, monitor.us2.qless.com,
  // calendar.na4.qless.com and select the specified application.

  // If `target` is specified, show the app picker with the target selected.

  if (target) {
    target = target.replace("/", "");
    var app = auth.locs[target] ? target : "cec";
    showAppPicker(app);
  } else if (hostname.toLowerCase().indexOf("kiosk") > -1) {
    document.getElementById("app").value = "kiosk";
  } else if (hostname.toLowerCase().indexOf("monitor") > -1) {
    document.getElementById("app").value = "monitor";
  } else if (hostname.toLowerCase().indexOf("calendar") > -1) {
    showAppPicker("calendar");
  } else {
    showCookiedApp();
  }
}

/**
 * Determines if the page was called with the logout switch
 *
 * @method checkLogout
 * @returns none
 */
function checkLogout() {
  var logout = auth.getQueryParam("logout");

  if (logout && logout !== null && logout !== undefined) {
    auth.logout();
  }
}

/**
 * Reads the last app the user chose from their cookies, sets
 * the app picker to that value, and displays the app picker
 *
 * @method showCookiedApp
 * @returns none
 */
function showCookiedApp() {
  showAppPicker(store.get("loginapp"));
}

/**
 * Initializes the page and app choice
 *
 * @method init
 * @returns none
 */
function init() {
  checkLogout();
  preselectApplication();
  makeRememberMeCheckboxAccessible();
}

/**
 * Callable function which reads known elements from the DOM to
 * obtain user credentials and then passes them to the Authenticator
 *
 * @method login
 * @return none
 */
function login() {
  var uname = document.getElementById("principal").value;
  var pword = document.getElementById("credential").value;
  var remember = document.getElementById("remember").checked;

  setLoginApp();

  if (!$("#login-submit").hasClass("btn-submit-logging")) {
    $("#loginFailed").addClass("is-hidden");
    $("#login-submit").addClass("btn-submit-logging");

    auth.login(uname, pword, remember);
  }
}

/**
 * Sets the cookie for loginapp
 *
 * @method setLoginApp
 * @returns none
 */
function setLoginApp() {
  store.set("loginapp", document.getElementById("app").value);
}

/**
 * Unhides the application selection
 *
 * @method showAppPicker
 * @param {String} defaultVal
 * @returns none
 */
function showAppPicker(defaultVal) {
  var el = document.getElementById("loginApplication");
  el.setAttribute("class", el.getAttribute("class").replace(" is-hidden", ""));
  document.getElementById("app").value = defaultVal || "cec";
}

/**
 * Shows / Hides the forgot password interface, allowing the user to request
 * or reset their password
 *
 * @method toggleForgot
 * @param {Boolean} force
 * @return none
 */
function toggleForgot(force) {
  if (!force) {
    $("#login-container").toggleClass("forgot");
  }

  var loginVis = $("#login-container").hasClass("forgot");
  var tab1 = loginVis ? -1 : 0;
  var tab2 = loginVis ? 0 : -1;

  $("#app, #principal, #credential, #login-submit, #rememberCheck").attr(
    "tabindex",
    tab1
  );
  $("#email, #forgot-submit").attr("tabindex", tab2);

  // if ($('#login-container').hasClass('forgot')) {
  // 	$('#login-submit').attr('disabled', true).css('cursor', 'default');
  // }
  // else {
  // 	$('#login-submit').attr('disabled', false).css('cursor', 'pointer');
  // }
}

/**
 * Enable the fake checkbox to respond to the SPACE and ENTER keys when
 * a User navigates and controls the form using the keyboard.
 */

function makeRememberMeCheckboxAccessible() {
  var fakeCheckbox = document.getElementById("rememberCheck");
  var realCheckbox = document.getElementById("remember");

  var SPACE_KEY = 32;
  var RETURN_KEY = 13;
  var COMBINED_KEY = 229; // Some browsers will send this when user's type a combined key such as those with diacritics.

  // Initialise Fake Checkbox
  fakeCheckbox.setAttribute("aria-checked", (realCheckbox.checked) ? "true" : "false")


  var toggleCheckbox = function () {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Assignment_Operators#Bitwise_XOR_assignment
    // Toggle the checked flag using XOR. 0 || 1 = 1, 1 || 1 = 0.
    // realCheckbox.checked ^= 1

    // Alternatively, this could be written as `realCheckbox.checked = !realCheckbox.checked
    // where we're just inverting/flipping the value.
    realCheckbox.checked = !realCheckbox.checked;
    fakeCheckbox.setAttribute("aria-checked", (realCheckbox.checked) ? "true" : "false")
  }

  fakeCheckbox.addEventListener(
    "keydown",
    function rememberMeToggleOnSpaceOrEnter() {
      if (event.isComposing || event.keyCode === COMBINED_KEY) {
        return;
      }

      if (event.keyCode == RETURN_KEY || event.keyCode == SPACE_KEY) {
        toggleCheckbox();
      }
    }
  );

  // Ensure that we let accessibility tools know the checkbox state
  fakeCheckbox.addEventListener("click", toggleCheckbox);
}

$(function () {
  init();
});
